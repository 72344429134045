<template>
  <Popup ref="popupRef" width="500px" title="岗位刷新" :confirmLoading="isLoading"  @close="resetData" @confirm="confirm">
    <div class="flex items-center">
      <span>岗位刷新时间：</span>
      <el-date-picker
        class="flex-1"
        v-model="refreshTime"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetime">
      </el-date-picker>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { postRefreshTimeApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      refreshTime: '',
      pid: '',
      isLoading: false
    }
  },
  methods: {
    open(option) {
      console.log('option',option)
      this.pid = option.pid || ''
      this.refreshTime = option.refreshTime || ''
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.pid = ''
      this.refreshTime = ''
    },
    confirm() {
      const formData = {
        pid: this.pid,
        refreshTime: this.refreshTime
      }
      console.log('formData', formData)
      this.isLoading = true
      postRefreshTimeApi(formData).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.isLoading = false
        this.close()
        this.$emit('update')
      }).catch(err => {
        this.isLoading = false
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>