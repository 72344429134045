import request from '@/utils/request'

/**
  * 获取路由菜单
  */
export function postListApi(data = {}) {
  return request({
    url: 'post/list',
    method: 'POST',
    data,
  })
}

/**
  * 职位详情
  */
export function postDetailApi(data = {}) {
  return request({
    url: 'post/detail',
    method: 'POST',
    data,
  })
}

