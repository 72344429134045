<template>
  <Popup ref="popupRef" width="500px" :showConfirm="false" :showCancel="false" @close="resetData">
    <div v-if="contacts && contacts.length > 0">
      <div class="title">商家联系方式</div>
      <div class="row" v-for="(item, index) in contacts" :key="index">
        <div class="name">{{item.name}}：</div>
        <div v-if="item.type != 7">{{item.value}}</div>
        <LoadImage v-else class="img" :src="item.qrCodeUrl" preview></LoadImage>
      </div>
    </div>
    <div v-if="contactRules && contactRules.length > 0">
      <div class="title mt30">多联系方式</div>
      <div v-for="(item, index) in contactRules" :key="index">
        <div class="row" v-if="item.contactType == 1">
          <div class="name">微信：</div>
          <div>{{item.contactValue}}</div>
        </div>
        <div class="row" v-if="item.contactType == 2">
          <div class="name">QQ：</div>
          <div>{{item.contactValue}}</div>
        </div>
        <div class="row" v-if="item.contactType == 3">
          <div class="name">手机号：</div>
          <div>{{item.contactValue}}</div>
        </div>
        <div class="row" v-if="item.contactType == 8">
          <div class="name">在线沟通：</div>
          <div>{{item.mobile}}</div>
        </div>
        <div class="row" v-if="item.contactType == 7">
          <div class="name">微信二维码：</div>
          <LoadImage class="img" :src="item.qrCodeUrl" preview></LoadImage>
        </div>
        
      </div>
    </div>
    <el-empty v-if="(!contacts || contacts.length == 0) && (!contactRules || contactRules.length == 0)" description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import LoadImage from '@/components/LoadImage'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  components: {
    Popup,
    LoadImage
  },
  data() {
    return {
      contacts: [],
      contactRules: []
    }
  },
  methods: {
    open(info) {
      this.contacts = info.contacts || []
      this.contactRules = info.contactRules || []

      this.contacts.forEach(item => {
        if(item.type == 7) {
          if(item.value.startsWith('http')) {
            this.$set(item, 'qrCodeUrl', item.value)
          } else {
            ossKeyToUrl({key: item.value.startsWith('oss') ? item.value : 'oss://' + item.value}).then(ossRes => {
              this.$set(item, 'qrCodeUrl', ossRes.url)
            })
          }
        }
      })

      this.contactRules.forEach(item => {
        if(item.contactType == 7) {
          if(item.contactValue.startsWith('http')) {
            this.$set(item, 'qrCodeUrl', item.contactValue)
          } else {
            ossKeyToUrl({key: item.contactValue.startsWith('oss') ? item.contactValue : 'oss://' + item.contactValue}).then(ossRes => {
              this.$set(item, 'qrCodeUrl', ossRes.url)
            })
          }
        }
      })
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.contacts = []
      this.contactRules = []
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
.row {
  display: flex;
  margin-bottom: 10px;
  .name {
    width: 100px;
    font-size: 14px;
    text-align: right;
  }
  .img {
    width: 100px;
    height: 100px;
  }
}
</style>